import {
  marketCloseRecords,
  forcedClosing,
  forcedOpening
} from './closingApi'
import dayjs from "dayjs";
import {
  ElMessage,
  ElMessageBox
} from 'element-plus'

// 收盘数据
export const closingData = (data) => {
  let param = {
    pageIndex: data.pageIndex,
    pageSize: data.pageSize,
    state: data.state,
    type:data.movement,
  }
  marketCloseRecords(param).then((res) => {
    let newRes = res.data
    if (newRes.code == 200) {
      newRes.data.marketCloseRecords.forEach(item => {
        item.createTime = dayjs(item.createTime).format("YYYY-MM-DD HH:mm:ss")
        item.date = dayjs(item.date).format("YYYY-MM-DD HH:mm:ss")
        item.state === 0 ? item.state = '成功' : item.state = '失败'
        // if(item.state === 0){
        //   item.state = '成功'
        // }else
      });
      data.tableData = newRes.data.marketCloseRecords
      data.total = newRes.data.total
    }
  })
}
// 强制收盘操作
export const forcedClosings = (data, row) => {
  ElMessageBox.confirm(
      '强制收盘将会出现数据错乱,是否执行？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }
    )
    .then(() => {
      let param = {
        id: row.id
      }
      forcedClosing(param).then(res => {
        ElMessage.success(res.data.data)
        // 强制收盘结束后刷新页面
        closingData(data)
      })
    })
    .catch(() => {
      ElMessage.info('已取消')
    })

}


// 强制开盘
export const openingQuotations = (data, row) => {
  ElMessageBox.confirm(
      '强制开盘将会出现数据错乱,是否执行？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }
    )
    .then(() => {
      let param = {
        id: row.id
      }
      forcedOpening(param).then(res => {
        ElMessage.success(res.data.data)
        // 强制开盘结束后刷新页面
        closingData(data)
      })
    })
    .catch(() => {
      ElMessage.info('已取消')
    })
}